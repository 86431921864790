body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}
.mainContainer {
  padding-top: 40px;
  padding-bottom: 40px;
}
.configContainer .header {
  margin-bottom: 10px;
}
.configContainer .saveWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.configContainer .flagsWrapper {
  display: flex;
}
.configContainer .key {
  padding-bottom: 10px;
  margin: 20px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.configContainer .key .keyHeader {
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  margin-bottom: 10px;
}
.configContainer .key .keyName {
  margin: 0;
}
.configContainer .key .right,
.configContainer .key .left {
  display: flex;
  align-items: center;
}
.configContainer .key .deprecateButton {
  margin-right: 15px;
}
.configContainer .key .deleteButton {
  margin-left: 15px;
}
.configContainer .checkboxLabel {
  margin-right: 5px;
}
.buildConfigContainer .header {
  margin-bottom: 10px;
}
.buildConfigContainer .saveWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.buildConfigContainer .flagsWrapper {
  display: flex;
}
.buildConfigContainer .key {
  padding-bottom: 10px;
  margin: 20px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.buildConfigContainer .checkboxLabel {
  margin-right: 5px;
}
.stringsContainer .order0IsNew .order-0 .itemContent::before {
  content: "🚨🆕";
}
.resourceVersionsContainer .versionEntryField {
  width: 80px;
}
.resourceVersionsContainer .saveButton {
  width: 200px;
  margin: 0px auto;
}
.uploadingAssets {
  margin-top: 15px;
}
.uploadingAsset {
  margin: 15px 0;
}
.uploadButton {
  margin-top: 15px;
}
@media (max-width: 768px) {
  .configContainer .header h1 {
    font-size: 1.5rem;
  }
  .buildConfigContainer .header h1 {
    font-size: 1.5rem;
  }
}
.manageReleasesContainer .row {
  margin-bottom: 50px;
}
