.manageResourcesHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.manageResources .loaderWrapper {
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .manageResourcesHeader h1 {
    font-size: 1.5rem;
  }
}
