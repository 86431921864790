.imageItem {
  width: 200px;
  display: flex;
  flex-direction: column;
  margin: 0 60px 30px 0;
}
.spaceBetween {
  justify-content: space-between;
}
.imageItemHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.imageItemHeader h5 {
  font-size: 1em;
}
.imageItem .imageWrapper {
  display: flex;
  flex-direction: column;
  width: 120px;
  align-items: center;
}
.imageItem .imageItemSize {
  margin: 0;
  font-size: 0.8em;
  color: #495057;
}
.imageItem .imageInput {
  display: none;
}
.imageItem .imageLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.125);
  cursor: pointer;
}
.imageItem .imageLabel .imageChoose {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.imageItem .imageLabel .imageChoose:hover {
  background-color: aliceblue;
}
.imageItem .imageLabel img {
  max-width: 100%;
}
