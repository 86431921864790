.resourceSection {
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.resourceSection.newItem:before {
  content: "🚨🆕";
}
.resourceSectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.resourceSection .deleteButton {
  margin-left: 15px;
}
