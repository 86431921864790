.imageSectionList .imagesInputWrapper {
  margin-bottom: 15px;
}

.imageSectionList .addSectionForm,
.imageSectionList .addImageForm {
  width: 320px;
  height: 120px;
  margin-bottom: 42px;
}

.imageSectionList .imagesWrapper {
  display: flex;
  flex-wrap: wrap;
}
